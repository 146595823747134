import React, { Suspense, lazy, useEffect } from 'react';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Loader,
  Analytics,
  ProtectedRoute,
  LogoutPageView,
  SsoCallbackPage,
  appGlobalStyles,
  AppThemeProvider,
  TrackPageviewEvent,
} from '@clatter/ui';
import {
  useAuth,
  sharedRoutes,
  fetchToolsConfig,
  fetchToolInfo,
  fetchFeatureFlag,
  fetchSystemProfile,
} from '@clatter/platform';
import { createGlobalStyle } from 'styled-components';
import routes from './routes';
import { fetchAllTemplates } from './Store/templates.slice';
import { userRolesMap } from './helpers';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const LandingPageView = lazy(() =>
  import('./Pages/LandingPage/LandingPageView'),
);
const DocumentsListView = lazy(() =>
  import('./Pages/Documents/DocumentsListView'),
);
const DocumentsAddView = lazy(() =>
  import('./Pages/Documents/DocumentsAdd/DocumentAddView'),
);
const DocumentsEditView = lazy(() =>
  import('./Pages/Documents/DocumentEdit/DocumentEditView'),
);
const TemplatesListView = lazy(() =>
  import('./Pages/Templates/TemplatesListView'),
);
const TemplatesAddView = lazy(() =>
  import('./Pages/Templates/TemplateAdd/TemplateAddView'),
);
const TemplateBuildView = lazy(() =>
  import('./Pages/Templates/TemplateBuild/TemplateBuildView'),
);
const DocumentsByUserReportView = lazy(() =>
  import('./Pages/Reports/DocumentsByUser/DocumentsByUserReportView'),
);
const DocumentsDownloadsReportView = lazy(() =>
  import('./Pages/Reports/DocumentsDownloads/DocumentsDownloadsReportView'),
);
const DocumentsCreateReportView = lazy(() =>
  import('./Pages/Reports/DocumentsCreate/DocumentsCreateReportView'),
);
const TemplateUsageReportView = lazy(() =>
  import('./Pages/Reports/TemplateUsage/TemplateUsageReportView'),
);

const App = () => {
  const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;
  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();

  const isOnLogoutPage = !!matchPath(routes.logout, location.pathname);
  const isOnSsoCallbackPage = !!matchPath(sharedRoutes.ssoCallback, location.pathname);

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
      dispatch(fetchAllTemplates());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />

      <TrackPageviewEvent />
      <Analytics />

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={routes.index} element={<LandingPageView />} />
          <Route path={routes.logout} element={<LogoutPageView />} />
          <Route path={sharedRoutes.ssoCallback} element={<SsoCallbackPage />} />

          <Route
            path={routes.documents}
            element={
              <ProtectedRoute
                element={DocumentsListView}
                accessRoles={[
                  userRolesMap.summaryMaker,
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcUser,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeUser,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitUser,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmUser,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.addDocument}
            element={
              <ProtectedRoute
                element={DocumentsAddView}
                accessRoles={[
                  userRolesMap.summaryMaker,
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcUser,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeUser,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitUser,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmUser,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.editDocument}
            element={
              <ProtectedRoute
                element={DocumentsEditView}
                accessRoles={[
                  userRolesMap.summaryMaker,
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcUser,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeUser,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitUser,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmUser,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.templates}
            element={
              <ProtectedRoute
                element={TemplatesListView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.addTemplates}
            element={
              <ProtectedRoute
                element={TemplatesAddView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.buildTemplate}
            element={
              <ProtectedRoute
                element={TemplateBuildView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.reportTemplatesUsage}
            element={
              <ProtectedRoute
                element={TemplateUsageReportView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.reportDocumentsByUser}
            element={
              <ProtectedRoute
                element={DocumentsByUserReportView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.reportDocumentsDownloads}
            element={
              <ProtectedRoute
                element={DocumentsDownloadsReportView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />

          <Route
            path={routes.reportDocumentsCreate}
            element={
              <ProtectedRoute
                element={DocumentsCreateReportView}
                accessRoles={[
                  userRolesMap.summaryMakerAdmin,
                  userRolesMap.qcAdmin,
                  userRolesMap.oeAdmin,
                  userRolesMap.oeKitAdmin,
                  userRolesMap.cmAdmin,
                ]}
              />
            }
          />
        </Routes>
      </Suspense>
    </AppThemeProvider>
  );
};
export default App;
